import LocationIcon from '$src/icons/location-icon.svg?raw'
import { scrollToElementMobile } from '$src/modules/utils/dom'
import { Solid } from '$src/modules/utils/solid'
import { getUserLocation, search, searchCityByName } from '../search-store'
import { SearchAutocomplete } from '../search-autocomplete/solid'
import { SearchResults } from '../search-results/solid'

function SeachFormHome() {
  return (
    <form
      autocomplete="off"
      id="search-form-home"
      onReset={getUserLocation}
      onSubmit={submitSearchForm}
      classList={{ loading: search.get.loading }}
    >
      <fieldset>
        <input
          required
          type="search"
          autocomplete="off"
          name="search-input"
          placeholder="Busca por cidade"
          value={search.get.text}
          readonly={search.get.loading}
          onFocus={scrollToTop}
          onInput={setSearchText}
        />

        <button
          type="reset"
          class="reset flex-center"
          name="Usar minha localização"
          aria-label="Usar minha localização"
          disabled={search.get.loading}
        >
          <figure innerHTML={LocationIcon} />
          <span>Usar minha localização</span>
        </button>

        <SearchAutocomplete />
      </fieldset>

      <button type="submit" class="btn primary" disabled={search.get.loading}>
        <span class="submit">Ok</span>
      </button>
    </form>
  )
}
Solid.render('#search-home', SeachFormHome)

// Helpers
function scrollToTop(e: Event) {
  const input = e.target as HTMLInputElement
  const form = input.closest('form') as HTMLFormElement
  scrollToElementMobile(form, 75)
}
function setSearchText(e: Event) {
  const input = e.target as HTMLInputElement
  search.set({ text: input.value })
}
function submitSearchForm(e: Event) {
  e.preventDefault()
  searchCityByName()
}
