import { Solid } from '$src/modules/utils/solid'
import { pageLink } from '$src/modules/utils/url'
import { search } from '../search-store'
import './styles.pcss'

export function SearchAutocomplete(props: { context?: string }) {
  return (
    <nav id="search-autocomplete">
      <Solid.For each={search.get.results}>
        {(result) => (
          <a href={pageLink(result.slug, props.context)}>
            {result.nome} - {result.uf}
          </a>
        )}
      </Solid.For>
    </nav>
  )
}
