import './actions.pcss'
import { $$ } from '@jsweb/utils/web/dom'
import { Solid } from '$src/modules/utils/solid'
import SearchIcon from '$src/icons/search-icon.svg?raw'
import { SearchModal } from '$src/components/location/search-modal/solid'

interface Props {
  context: string
}

export function HomeCitiesActions(props: Props) {
  const store = Solid.createStore({ modal: false })

  return (
    <>
      <button
        type="button"
        class="btn accent flex-1"
        data-context={props.context}
        onClick={() => store.set({ modal: true })}
      >
        <figure innerHTML={SearchIcon} />
        <small>Outras Cidades</small>
      </button>

      <a href="/clima-todas-as-cidades-brasil/" class="flex-center flex-1">
        <small>Ver todas as cidades</small>
      </a>

      <SearchModal
        context={props.context}
        open={store.get.modal}
        onClose={() => store.set({ modal: false })}
      />
    </>
  )
}

$$('.actions').map((el) => {
  const uuid = el.getAttribute('data-uuid')
  const context = el.getAttribute('data-context') || '[slug]'

  Solid.render(`.actions[data-uuid="${uuid}"]`, () => (
    <HomeCitiesActions context={context} />
  ))
})
